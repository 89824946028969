import { Tyre } from "./api/TyreApi";
import { Pricing, Token } from "./api/AuthApi";
import FuelA from "./img/fuel_efficiency_A.svg";
import FuelB from "./img/fuel_efficiency_B.svg";
import FuelC from "./img/fuel_efficiency_C.svg";
import FuelD from "./img/fuel_efficiency_D.svg";
import FuelE from "./img/fuel_efficiency_E.svg";
import WetA from "./img/wet_road_efficiency_A.svg";
import WetB from "./img/wet_road_efficiency_B.svg";
import WetC from "./img/wet_road_efficiency_C.svg";
import WetD from "./img/wet_road_efficiency_D.svg";
import WetE from "./img/wet_road_efficiency_E.svg";
import NoiseA from "./img/noise_A.png";
import NoiseB from "./img/noise_B.png";
import NoiseC from "./img/noise_C.png";
import Summer from "./img/summer.png";
import Winter from "./img/winter.png";
import Allseason from "./img/allseason.png";
import { Brand } from "./api/BrandApi";
import React from "react";

export function getCorrectPrice(tyre: Tyre, pricing: Pricing) {
  let price = tyre.priceBC;

  if (pricing.priceType === "Garage12") {
    price = tyre.priceGarage12;
  }

  if (pricing.priceType === "Garage") {
    price = tyre.priceGarage;
  }

  let recupel = pricing.recupelC1;

  if (tyre.environmentCategory === "C2") {
    recupel = pricing.recupelC2;
  }

  return parseFloat(
    (
      ((price + pricing.transportCost + recupel) * (100 + pricing.vat)) /
      100
    ).toFixed(2)
  );
}

export function getCorrectFuelImage(index: string) {
  switch (index) {
    case "A":
      return FuelA;
    case "B":
      return FuelB;
    case "C":
      return FuelC;
    case "D":
      return FuelD;
    case "E":
      return FuelE;
  }
}

export function getCorrectWetImage(index: string) {
  switch (index) {
    case "A":
      return WetA;
    case "B":
      return WetB;
    case "C":
      return WetC;
    case "D":
      return WetD;
    case "E":
      return WetE;
  }
}

export function getCorrectNoiseImage(index: string) {
  switch (index) {
    case "A":
      return NoiseA;
    case "B":
      return NoiseB;
    case "C":
      return NoiseC;
  }
}

export function getCorrectKindImage(index: string) {
  switch (index) {
    case "Summer":
      return Summer;
    case "Winter":
      return Winter;
    case "4-Season":
      return Allseason;
  }
}

export function capitalize(item: string) {
  return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
}

export function parseIntOrReturnValue(item: string) {
  try {
    const result = parseFloat(item);

    if (isNaN(result)) {
      return item;
    }

    return result;
  } catch (e) {
    return item;
  }
}

export function sortValue(a: string | number, b: string | number) {
  if (typeof a === "string") {
    return 1;
  }

  if (typeof b === "string") {
    return -1;
  }

  return a - b;
}

export function getMinute(time: string) {
  return time.split(":")[1] || "";
}

export function getHour(time: string) {
  return time.split(":")[0] || "";
}

export function tokenIsExpired(token: Token) {
  if (Date.now() >= token.exp * 1000) {
    localStorage.removeItem("token");
    return true;
  }

  return false;
}

export function compareOptions(
  a: { label: string; value: string },
  b: { label: string; value: string }
) {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
}

export function compareBrands(a: Brand, b: Brand) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export function isBE() {
  return (
    window.location.host.includes("localhost") ||
    window.location.host.includes(".be")
  );
}

export function isNL() {
  return window.location.host.includes(".nl");
}

export function isFR() {
  return window.location.host.includes("pneus");
}

export function isStaging() {
  return process.env.REACT_APP_STAGING === "true";
}

export function getCountry() {
  return isBE() ? "be" : "nl";
}

export function getAlternate() {
  const urls = [
    { url: "https://banden-online-kopen.be", lang: "nl-be" },
    { url: "https://banden-online-kopen.nl", lang: "nl-nl" },
    { url: "https://pneus-enligne.be", lang: "fr-be" },
    { url: "https://banden-koning.nl", lang: "nl-nl" },
    { url: "https://pneusfrance.fr", lang: "fr-fr" },
  ];

  return urls.map(item => {
    return (
      <link
        rel="alternate"
        hrefLang={item.lang}
        href={`${item.url}${window.location.pathname}`}
      />
    );
  });
}
