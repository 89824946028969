import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Order = {
  orderId: string;
  userId: string;
  createdAt: string;
  orderStatus: string;
  synced: boolean;
  tyres: {
    tyreId: string;
    amount: number;
    recupel: number;
    price: number;
    transport: number;
  }[];
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  partnerId: string;
  deliveryStreet: string;
  deliveryNumber: string;
  deliveryZipcode: string;
  deliveryCity: string;
  deliveryCountry: string;
  billingStreet: string;
  billingNumber: string;
  billingZipcode: string;
  billingCity: string;
  billingCountry: string;
  externalOrderId: string;
  price: number;
  vat: number;
  recupel: number;
  transport: number;
  transactionCost: number;
  onePieceExtra: number;
};

export async function getOrders(
  page: number,
  search: {
    date: string;
    orderId: string;
    name: string;
    status: string;
  }
): Promise<Order[]> {
  const urlParams = new URLSearchParams();
  urlParams.set("page", page.toString());
  urlParams.set("date", search.date);
  urlParams.set("orderId", search.orderId);
  urlParams.set("name", search.name);
  urlParams.set("status", search.status);

  const result = await fetch(`${API_ENDPOINT}/orders?${urlParams.toString()}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  if (!result.orders) {
    return [];
  }

  return result.orders;
}

export async function getOrder(orderId: string): Promise<Order | undefined> {
  const result = await fetch(`${API_ENDPOINT}/orders/${orderId}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  if (!result.order) {
    return undefined;
  }

  return result.order;
}

export async function syncOrder(orderId: string): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/sync/${orderId}`, {
    method: "POST",
    headers: getHeaders(),
  });

  return result.ok;
}

export async function syncAllProds(): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/sync-all-prods`, {
    method: "POST",
    headers: getHeaders(),
  });

  return result.ok;
}

export async function syncAllStocks(): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/sync-all-stocks`, {
    method: "POST",
    headers: getHeaders(),
  });

  return result.ok;
}
