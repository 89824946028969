import React, { useState, useEffect } from "react";
import { Select } from "./Select";
import "./Search.css";
import { TyreToWatch } from "./components/TyreToWatch";
import {
  Tyre,
  getTyres,
  SearchOptions,
  getTopSale,
  getWebPromo,
  getSearchOptions,
} from "./api/TyreApi";
import { useEffectOnce, usePrevious } from "react-use";
import { Brand } from "./api/BrandApi";
import { Pricing } from "./api/AuthApi";
import {
  getCorrectPrice,
  capitalize,
  parseIntOrReturnValue,
  sortValue,
  isNL,
  getAlternate,
} from "./Utils";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Checkbox } from "./Checkbox";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import tooltipImage from "./img/tooltip.svg";
import { Selection } from "./components/SearchBar";
import { Loading } from "./Loading";
import { Pagination } from "./components/Pagination";
import { Helmet } from "react-helmet";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faFilter,
  faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import { TyreCard } from "./components/TyreCard";

export function paginate(items: Tyre[], pageNumber: number, pageSize: number) {
  const startIndex = (pageNumber - 1) * pageSize;

  return _(items)
    .slice(startIndex)
    .take(pageSize)
    .value();
}

export function Search({
  brands,
  pricing,
  options,
}: {
  brands: Brand[];
  pricing: Pricing;
  options: SearchOptions;
}) {
  const [tyres, setTyres] = useState<Tyre[]>([]);
  const [hasNext, setHasNext] = useState(false);
  const [topSale, setTopSale] = useState<Tyre[]>([]);
  const [promo, setPromo] = useState<Tyre[]>([]);
  const [filteredTopSale, setFilteredTopSale] = useState<Tyre[]>([]);
  const [filteredPromo, setFilteredPromo] = useState<Tyre[]>([]);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [runflat, setRunFlat] = useState(
    searchParams.get("runflat") === "true"
  );
  const [localSearchOptions, setLocalSearchOptions] = useState(options);
  const [height, setHeight] = useState(searchParams.get("height") || "");
  const [width, setWidth] = useState(searchParams.get("width") || "");
  const [diameter, setDiameter] = useState(searchParams.get("diameter") || "");
  const [season, setSeason] = useState(searchParams.get("season") || "");
  const [vehicleType, setVehicleType] = useState(
    searchParams.get("vehicleType") || ""
  );
  const [brand, setBrand] = useState(searchParams.get("brand") || "");
  const [fuelEfficiency, setFuelEfficiency] = useState(
    searchParams.get("fuelEfficiency") || ""
  );
  const [wetRoadEfficiency, setWetRoadEfficiency] = useState(
    searchParams.get("wetRoadEfficiency") || ""
  );
  const [loadIndex, setLoadIndex] = useState(
    searchParams.get("loadIndex") || ""
  );
  const [speedIndex, setSpeedIndex] = useState(
    searchParams.get("speedIndex") || ""
  );
  const [sort, setSort] = useState(searchParams.get("sort") || "");
  const [firstSelected, setFirstSelected] = useState<null | Selection>();
  const history = useHistory();
  const { t } = useTranslation("general");
  const [loading, setLoading] = useState(true);
  const previousSort = usePrevious(sort);
  const [openSearch, setOpenSearch] = useState(false);

  const availableBrands = brands.filter(b =>
    localSearchOptions.brand.includes(b.name)
  );

  useEffectOnce(() => {
    getTyres(page, location.search).then(result => {
      setTyres(result.tyres);
      setHasNext(result.next);
      setLoading(false);
    });

    getTopSale().then(result => {
      setTopSale(result);
      setFilteredTopSale(result.filter(p => filterExtra(p)));
    });

    getWebPromo().then(result => {
      setPromo(result);
      setFilteredPromo(result.filter(p => filterExtra(p)));
    });

    setLocalSearchOptions(options);
  });

  useEffect(() => {
    setLoading(true);
    getTyres(page, location.search).then(result => {
      setTyres(result.tyres);
      setHasNext(result.next);
      setLoading(false);
    });
  }, [page]);

  const [currentPagePromo, setCurrentPagePromo] = useState(1);
  const [currentPageTopSale, setCurrentPageTopSale] = useState(1);

  const pageSize = isMobile ? 4 : 3;

  function filterExtra(p: Tyre) {
    let result = true;

    if (height && height !== "" && p.height !== height) {
      result = false;
    }

    if (width && width !== "" && p.width !== width) {
      result = false;
    }

    if (diameter && diameter !== "" && p.radius !== parseInt(diameter)) {
      result = false;
    }

    if (season && season !== "" && p.season !== season) {
      result = false;
    }

    if (vehicleType && vehicleType !== "" && p.type !== vehicleType) {
      result = false;
    }

    if (brand && brand !== "" && p.brandId !== brand) {
      result = false;
    }

    if (loadIndex && loadIndex !== "" && p.loadIndex !== loadIndex) {
      result = false;
    }

    if (speedIndex && speedIndex !== "" && p.speedIndex !== speedIndex) {
      result = false;
    }

    if (
      fuelEfficiency &&
      fuelEfficiency !== "" &&
      p.fuelEfficiency !== fuelEfficiency
    ) {
      result = false;
    }

    if (
      wetRoadEfficiency &&
      wetRoadEfficiency !== "" &&
      p.wetRoadEfficiency !== wetRoadEfficiency
    ) {
      result = false;
    }

    if (runflat && p.runFlat !== runflat) {
      result = false;
    }

    return result;
  }

  const getPageDataPromo = () => {
    const paginationData = paginate(filteredPromo, currentPagePromo, pageSize);
    return { totalCountPromo: filteredPromo.length, dataPromo: paginationData };
  };

  const { totalCountPromo, dataPromo } = getPageDataPromo();

  const pageCountPromo = Math.ceil(totalCountPromo / pageSize);
  const nextPagePromo = (increment: number) => {
    if (
      currentPagePromo + increment <= pageCountPromo &&
      currentPagePromo + increment > 0
    ) {
      setCurrentPagePromo(currentPagePromo + increment);
    } else if (pageCountPromo === currentPagePromo) {
      setCurrentPagePromo(1);
    } else if (increment === -1 && currentPagePromo === 1) {
      setCurrentPagePromo(pageCountPromo);
    }
  };

  const getPageDataTopSale = () => {
    const paginationData = paginate(
      filteredTopSale,
      currentPageTopSale,
      pageSize
    );
    return {
      totalCountTopSale: filteredTopSale.length,
      dataTopSale: paginationData,
    };
  };

  const { totalCountTopSale, dataTopSale } = getPageDataTopSale();

  const pageCountTopSale = Math.ceil(totalCountTopSale / pageSize);
  const nextPageTopSale = (increment: number) => {
    if (
      currentPageTopSale + increment <= pageCountTopSale &&
      currentPageTopSale + increment > 0
    ) {
      setCurrentPageTopSale(currentPageTopSale + increment);
    } else if (pageCountTopSale === currentPageTopSale) {
      setCurrentPageTopSale(1);
    } else if (increment === -1 && currentPageTopSale === 1) {
      setCurrentPageTopSale(pageCountTopSale);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("height", height);
    params.set("width", width);
    params.set("diameter", diameter);
    params.set("season", season);
    params.set("vehicleType", vehicleType);
    params.set("brand", brand);
    params.set("loadIndex", loadIndex);
    params.set("speedIndex", speedIndex);
    params.set("fuelEfficiency", fuelEfficiency);
    params.set("wetRoadEfficiency", wetRoadEfficiency);
    params.set("runflat", runflat ? "true" : "");

    getSearchOptions(params.toString()).then(result => {
      setLocalSearchOptions(result);
    });

    setFilteredTopSale(topSale.filter(p => filterExtra(p)));
    setFilteredPromo(promo.filter(p => filterExtra(p)));

    updateSearch(sort);
  }, [
    height,
    width,
    diameter,
    season,
    vehicleType,
    brand,
    loadIndex,
    speedIndex,
    fuelEfficiency,
    wetRoadEfficiency,
    runflat,
  ]);

  function updateSearch(newSort: string) {
    setLoading(true);
    const params = new URLSearchParams();
    params.set("height", height);
    params.set("width", width);
    params.set("diameter", diameter);
    params.set("season", season);
    params.set("vehicleType", vehicleType);
    params.set("brand", brand);
    params.set("loadIndex", loadIndex);
    params.set("speedIndex", speedIndex);
    params.set("fuelEfficiency", fuelEfficiency);
    params.set("wetRoadEfficiency", wetRoadEfficiency);
    params.set("runflat", runflat ? "true" : "");
    params.set("sort", newSort);

    setPage(1);

    getTyres(page, params.toString()).then(result => {
      setTyres(result.tyres);
      setHasNext(result.next);

      setLoading(false);
    });

    history.push(`/search?${params.toString()}`);
  }

  return (
    <div className="search-container">
      <Helmet>
        {getAlternate()}
        <title>
          {width !== "" && height !== "" && diameter !== ""
            ? width + "/" + height + " R" + diameter + !isNL()
              ? t("titles.search")
              : " Autobanden | Banden-Online-Kopen.nl!"
            : !isNL()
            ? t("titles.searchEmpty")
            : "Vind al uw autobanden op Banden-Online-Kopen.nl!"}
        </title>
      </Helmet>
      <div className="flex top">
        <div>
          <div className="breadcrumb mt-4">
            <Link to="/">Home</Link> &gt; Bandenmaten
            {width && height && diameter ? (
              <> &gt; {width + "/" + height + " R" + diameter}</>
            ) : null}
          </div>
          <h1 className="super-title mt-4 mb-4">
            {t("productRange")}{" "}
            {width !== "" && height !== "" && diameter !== ""
              ? +width + "/" + height + " R" + diameter
              : ""}
          </h1>
        </div>
        {isMobile ? (
          <>
            <div className="filter-container flex justify-between">
              <div
                className="flex items-center"
                onClick={e => {
                  e.preventDefault();

                  setOpenSearch(!openSearch);
                }}
              >
                <FontAwesomeIcon className="green fa-2x ml-5" icon={faFilter} />
                <div className="ml-5 green">
                  <b>Filter</b>
                </div>
              </div>
              <div className="flex items-center">
                <FontAwesomeIcon
                  className="green fa-2x mr-5"
                  icon={faSortAmountUp}
                />
                <div className="mr-5">
                  <Select
                    search={true}
                    title=""
                    tooltip={false}
                    options={[
                      { value: "", label: "" },
                      {
                        value: "up",
                        label: t("filter.ascending"),
                      },
                      {
                        value: "down",
                        label: t("filter.descending"),
                      },
                    ]}
                    selected={sort}
                    selectOption={selected => {
                      setSort(selected);
                      updateSearch(selected);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {!isMobile ? (
          <div className="ml-auto flex items-center extra-options">
            <Select
              search={true}
              title={t("filter.price").toString()}
              tooltip={false}
              options={[
                { value: "", label: "" },
                {
                  value: "up",
                  label: t("filter.ascending"),
                },
                {
                  value: "down",
                  label: t("filter.descending"),
                },
              ]}
              selected={sort}
              selectOption={selected => {
                setSort(selected);
                updateSearch(selected);
              }}
            />
          </div>
        ) : null}
      </div>
      <div className="flex search-results-container">
        {(isMobile && openSearch) || !isMobile ? (
          <div className="search-options flex flex-column">
            <div className="select">
              <label>{t("tyre.size")}</label>
              <img data-tip={"tooltip"} src={tooltipImage} alt="Tooltip" />
            </div>
            <div className="flex">
              <div className="mr-1 w-100">
                <Select
                  search={true}
                  title=""
                  tooltip={false}
                  options={[
                    { value: "", label: "" },
                    { value: "reset", label: "Reset" },
                  ].concat(
                    Array.from(
                      new Set(
                        (firstSelected === Selection.WIDTH
                          ? options.width
                          : localSearchOptions.width
                        )
                          .map(i => parseIntOrReturnValue(i))
                          .sort(sortValue)
                          .map(i => i.toString())
                      )
                    ).map(certainWidth => ({
                      value: certainWidth.toString(),
                      label: certainWidth.toString(),
                    }))
                  )}
                  selected={width}
                  selectOption={selected => {
                    setWidth(selected === "reset" ? "" : selected);

                    if (!firstSelected) {
                      setFirstSelected(Selection.WIDTH);
                    }

                    if (
                      selected === "reset" &&
                      firstSelected === Selection.WIDTH
                    ) {
                      setFirstSelected(null);
                    }
                  }}
                />
              </div>
              <div className="mr-1 w-100">
                <Select
                  search={true}
                  title=""
                  tooltip={false}
                  options={[
                    { value: "", label: "" },
                    { value: "reset", label: "Reset" },
                  ].concat(
                    Array.from(
                      new Set(
                        (firstSelected === Selection.HEIGHT
                          ? options.height
                          : localSearchOptions.height
                        )
                          .map(i => parseIntOrReturnValue(i))
                          .sort(sortValue)
                          .map(i => i.toString())
                      )
                    ).map(certainHeight => ({
                      value: certainHeight.toString(),
                      label: certainHeight.toString(),
                    }))
                  )}
                  selected={height}
                  selectOption={selected => {
                    setHeight(selected === "reset" ? "" : selected);

                    if (!firstSelected) {
                      setFirstSelected(Selection.HEIGHT);
                    }

                    if (
                      selected === "reset" &&
                      firstSelected === Selection.HEIGHT
                    ) {
                      setFirstSelected(null);
                    }
                  }}
                />
              </div>
              <Select
                search={true}
                title=""
                tooltip={false}
                options={[
                  { value: "", label: "" },
                  { value: "reset", label: "Reset" },
                ].concat(
                  (firstSelected === Selection.RADIUS
                    ? options.diameter
                    : localSearchOptions.diameter
                  )
                    .map(i => parseIntOrReturnValue(i))
                    .sort(sortValue)
                    .map(certainRadius => ({
                      value: certainRadius.toString(),
                      label: certainRadius.toString(),
                    }))
                )}
                selected={diameter}
                selectOption={selected => {
                  setDiameter(selected === "reset" ? "" : selected);

                  if (!firstSelected) {
                    setFirstSelected(Selection.RADIUS);
                  }

                  if (
                    selected === "reset" &&
                    firstSelected === Selection.RADIUS
                  ) {
                    setFirstSelected(null);
                  }
                }}
              />
            </div>
            <Select
              title={t("tyre.speedIndex").toString()}
              tooltip="Tooltip"
              options={[
                { value: "", label: "" },
                { value: "reset", label: "Reset" },
              ].concat(
                (firstSelected === Selection.SPEEDINDEX
                  ? options.speedIndex
                  : localSearchOptions.speedIndex
                )
                  .sort()
                  .map(speedRating => ({
                    value: speedRating.toString(),
                    label: speedRating.toString(),
                  }))
              )}
              selected={speedIndex}
              selectOption={selected => {
                setSpeedIndex(selected === "reset" ? "" : selected);

                if (!firstSelected) {
                  setFirstSelected(Selection.SPEEDINDEX);
                }

                if (
                  selected === "reset" &&
                  firstSelected === Selection.SPEEDINDEX
                ) {
                  setFirstSelected(null);
                }
              }}
              search={true}
            />
            <Select
              title={t("tyre.loadIndex").toString()}
              tooltip="Tooltip"
              options={[
                { value: "", label: "" },
                { value: "reset", label: "Reset" },
              ].concat(
                Array.from(
                  new Set(
                    (firstSelected === Selection.LOADINDEX
                      ? options.loadIndex
                      : localSearchOptions.loadIndex
                    )
                      .map(i => parseIntOrReturnValue(i))
                      .sort(sortValue)
                      .map(i => i.toString())
                  )
                ).map(certainWidth => ({
                  value: certainWidth.toString(),
                  label: certainWidth.toString(),
                }))
              )}
              selected={loadIndex}
              selectOption={selected => {
                setLoadIndex(selected === "reset" ? "" : selected);

                if (!firstSelected) {
                  setFirstSelected(Selection.LOADINDEX);
                }

                if (
                  selected === "reset" &&
                  firstSelected === Selection.LOADINDEX
                ) {
                  setFirstSelected(null);
                }
              }}
              search={true}
            />
            <Select
              search={true}
              title={t("tyre.brand").toString()}
              tooltip={false}
              options={[{ value: "", label: "" }].concat(
                availableBrands
                  .sort((a, b) => {
                    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                  })
                  .map(brand => ({
                    value: brand.id,
                    label: capitalize(brand.name),
                  }))
              )}
              selected={brand}
              selectOption={selected => {
                setBrand(selected);
              }}
            />
            <Select
              title={t("tyre.vehicle").toString()}
              tooltip={false}
              options={[
                { value: "", label: "" },
                { value: "reset", label: "Reset" },
              ].concat(
                (firstSelected === Selection.TYPE
                  ? options.vehicleType
                  : localSearchOptions.vehicleType
                )
                  .sort()
                  .map(type => ({
                    value: type.toString(),
                    label: type.toString(),
                  }))
              )}
              selected={vehicleType}
              selectOption={selected => {
                setVehicleType(selected === "reset" ? "" : selected);

                if (!firstSelected) {
                  setFirstSelected(Selection.TYPE);
                }

                if (selected === "reset" && firstSelected === Selection.TYPE) {
                  setFirstSelected(null);
                }
              }}
              search={true}
            />
            <Select
              title={t("tyre.wetGrip").toString()}
              tooltip={false}
              options={[
                { value: "", label: "" },
                { value: "reset", label: "Reset" },
              ].concat(
                (firstSelected === Selection.WET_ROAD_EFFICIENCY
                  ? options.wetRoadEfficiency
                  : localSearchOptions.wetRoadEfficiency
                )
                  .sort()
                  .map(speed => {
                    return {
                      value: speed.toString(),
                      label: speed.toString(),
                    };
                  })
              )}
              selected={wetRoadEfficiency}
              selectOption={selected => {
                setWetRoadEfficiency(selected === "reset" ? "" : selected);

                if (!firstSelected) {
                  setFirstSelected(Selection.WET_ROAD_EFFICIENCY);
                }

                if (
                  selected === "reset" &&
                  firstSelected === Selection.WET_ROAD_EFFICIENCY
                ) {
                  setFirstSelected(null);
                }
              }}
              search={true}
            />
            <Select
              title={t("tyre.fuelConsumption").toString()}
              tooltip={false}
              options={[
                { value: "", label: "" },
                { value: "reset", label: "Reset" },
              ].concat(
                (firstSelected === Selection.FUEL_EFFICIENCY
                  ? options.fuelEfficiency
                  : localSearchOptions.fuelEfficiency
                )
                  .sort()
                  .map(speed => {
                    return {
                      value: speed.toString(),
                      label: speed.toString(),
                    };
                  })
              )}
              selected={fuelEfficiency}
              selectOption={selected => {
                setFuelEfficiency(selected === "reset" ? "" : selected);

                if (!firstSelected) {
                  setFirstSelected(Selection.FUEL_EFFICIENCY);
                }

                if (
                  selected === "reset" &&
                  firstSelected === Selection.FUEL_EFFICIENCY
                ) {
                  setFirstSelected(null);
                }
              }}
              search={true}
            />
            <Select
              title={t("tyre.season.title").toString()}
              tooltip={false}
              options={[
                { value: "", label: "" },
                { value: "reset", label: "Reset" },
              ].concat(
                (firstSelected === Selection.SEASON
                  ? options.season
                  : localSearchOptions.season
                )
                  .sort()
                  .map(tyre => ({
                    value: tyre.toString(),
                    label: t(tyre.toString()),
                  }))
              )}
              selected={season}
              selectOption={selected => {
                setSeason(selected === "reset" ? "" : selected);

                if (!firstSelected) {
                  setFirstSelected(Selection.SEASON);
                }

                if (
                  selected === "reset" &&
                  firstSelected === Selection.SEASON
                ) {
                  setFirstSelected(null);
                }
              }}
              search={true}
            />
            <Checkbox
              title={t("tyre.runflat").toString()}
              value={runflat}
              onClick={checked => {
                setRunFlat(checked);
              }}
            />
            <div
              className="button"
              onClick={() => {
                updateSearch(sort);
              }}
            >
              {t("buttons.search")}
            </div>
            <div
              className="button mt-4"
              onClick={() => {
                const params = new URLSearchParams();
                params.set("height", height);
                params.set("width", width);
                params.set("diameter", diameter);
                params.delete("season");
                params.delete("vehicleType");
                params.delete("brand");
                params.delete("loadIndex");
                params.delete("speedIndex");
                params.delete("fuelEfficiency");
                params.delete("wetRoadEfficiency");
                params.delete("runflat");
                params.delete("sort");

                setSeason("");
                setVehicleType("");
                setBrand("");
                setLoadIndex("");
                setSpeedIndex("");
                setFuelEfficiency("");
                setWetRoadEfficiency("");
                setRunFlat(false);
                setSort("");

                setPage(1);

                history.push(`/search?${params.toString()}`);
              }}
            >
              Reset
            </div>
          </div>
        ) : null}

        {loading ? (
          <div className="flex justify-center items-center w-100">
            <Loading />
          </div>
        ) : (
          <div className="search-results mb-4">
            {dataTopSale.length > 0 ? (
              <>
                <h2 className="mt-4 mb-4">{t("recommended")}</h2>
                <div
                  className={
                    !isMobile
                      ? "toWatchContainer tyreToWatchContainer"
                      : "tyreCardContainer"
                  }
                >
                  {!isMobile ? (
                    <div className="arrow">
                      {pageCountTopSale > 1 ? (
                        <div
                          className="arrow-left"
                          onClick={() => nextPageTopSale(-1)}
                        />
                      ) : null}
                    </div>
                  ) : null}
                  {dataTopSale.map((tyre, index) => {
                    const brand = brands.find(b => b.id === tyre.brandId);

                    if (isMobile) {
                      return (
                        <TyreCard
                          key={index}
                          noise={tyre.noiseLevelDb}
                          noiseLevel={tyre.noiseClass}
                          height={tyre.height}
                          loadIndex={tyre.loadIndex}
                          radius={tyre.radius}
                          speedIndex={tyre.speedIndex}
                          width={tyre.width}
                          id={tyre.id}
                          profile={tyre.name}
                          brand={brand?.name || ""}
                          price={getCorrectPrice(tyre, pricing)}
                          vehicleType={tyre.type}
                          season={tyre.season}
                          competitors={[]}
                          logos={[]}
                          wetRoadEfficiency={tyre.wetRoadEfficiency}
                          fuelEfficiency={tyre.fuelEfficiency}
                          brandId={tyre.brandId}
                          image={tyre.image}
                          topSale={true}
                        />
                      );
                    } else {
                      return (
                        <TyreToWatch
                          key={index}
                          height={tyre.height}
                          noise={tyre.noiseLevelDb}
                          noiseLevel={tyre.noiseClass}
                          loadIndex={tyre.loadIndex}
                          radius={tyre.radius}
                          speedIndex={tyre.speedIndex}
                          width={tyre.width}
                          id={tyre.id}
                          title={tyre.name}
                          model={brand?.name || ""}
                          price={getCorrectPrice(tyre, pricing)}
                          car={tyre.type}
                          kind={tyre.season}
                          competitors={[]}
                          logos={[]}
                          wetRoadEfficiency={tyre.wetRoadEfficiency}
                          fuelEfficiency={tyre.fuelEfficiency}
                          brandId={tyre.brandId}
                          image={tyre.image}
                          topSale={true}
                        />
                      );
                    }
                  })}

                  {!isMobile ? (
                    <div className="arrow">
                      {pageCountTopSale > 1 ? (
                        <div
                          className="arrow-right"
                          onClick={() => nextPageTopSale(+1)}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
                {isMobile ? null : (
                  <Pagination
                    itemsCount={totalCountTopSale}
                    pageSize={pageSize}
                    currentPage={currentPageTopSale}
                    updatePage={setCurrentPageTopSale}
                  />
                )}
              </>
            ) : null}
            {dataPromo.length > 0 ? (
              <>
                <h2>{t("spotlight")}</h2>
                <div
                  className={
                    !isMobile
                      ? "toWatchContainer tyreToWatchContainer"
                      : "tyreCardContainer"
                  }
                >
                  {!isMobile ? (
                    <div className="arrow">
                      {pageCountPromo > 1 ? (
                        <div
                          className="arrow-left"
                          onClick={() => nextPagePromo(-1)}
                        />
                      ) : null}
                    </div>
                  ) : null}

                  {dataPromo.map((tyre, index) => {
                    const brand = brands.find(b => b.id === tyre.brandId);

                    if (isMobile) {
                      return (
                        <TyreCard
                          key={index}
                          noise={tyre.noiseLevelDb}
                          noiseLevel={tyre.noiseClass}
                          height={tyre.height}
                          loadIndex={tyre.loadIndex}
                          radius={tyre.radius}
                          speedIndex={tyre.speedIndex}
                          width={tyre.width}
                          id={tyre.id}
                          profile={tyre.name}
                          brand={brand?.name || ""}
                          price={getCorrectPrice(tyre, pricing)}
                          vehicleType={tyre.type}
                          season={tyre.season}
                          competitors={[]}
                          logos={[]}
                          wetRoadEfficiency={tyre.wetRoadEfficiency}
                          fuelEfficiency={tyre.fuelEfficiency}
                          brandId={tyre.brandId}
                          image={tyre.image}
                          promo={true}
                        />
                      );
                    } else {
                      return (
                        <TyreToWatch
                          key={index}
                          height={tyre.height}
                          noise={tyre.noiseLevelDb}
                          noiseLevel={tyre.noiseClass}
                          loadIndex={tyre.loadIndex}
                          radius={tyre.radius}
                          speedIndex={tyre.speedIndex}
                          width={tyre.width}
                          id={tyre.id}
                          title={tyre.name}
                          model={brand?.name || ""}
                          price={getCorrectPrice(tyre, pricing)}
                          car={tyre.type}
                          kind={tyre.season}
                          competitors={[]}
                          logos={[]}
                          wetRoadEfficiency={tyre.wetRoadEfficiency}
                          fuelEfficiency={tyre.fuelEfficiency}
                          brandId={tyre.brandId}
                          image={tyre.image}
                          promo={true}
                        />
                      );
                    }
                  })}

                  {!isMobile ? (
                    <div className="arrow">
                      {pageCountPromo > 1 ? (
                        <div
                          className="arrow-right"
                          onClick={() => nextPagePromo(1)}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
                {isMobile ? null : (
                  <Pagination
                    itemsCount={totalCountPromo}
                    pageSize={pageSize}
                    currentPage={currentPagePromo}
                    updatePage={setCurrentPagePromo}
                  />
                )}
              </>
            ) : null}
            <div className="flex wrap">
              {tyres.length === 0 ? (
                <div className="w-100 flex justify-center">
                  Momenteel geen resultaten gevonden
                </div>
              ) : null}
              {tyres.map((tyre, index) => {
                const brand = brands.find(b => b.id === tyre.brandId);

                if (isMobile) {
                  return (
                    <TyreCard
                      key={index}
                      noise={tyre.noiseLevelDb}
                      noiseLevel={tyre.noiseClass}
                      height={tyre.height}
                      loadIndex={tyre.loadIndex}
                      radius={tyre.radius}
                      speedIndex={tyre.speedIndex}
                      width={tyre.width}
                      id={tyre.id}
                      profile={tyre.name}
                      brand={brand?.name || ""}
                      price={getCorrectPrice(tyre, pricing)}
                      vehicleType={tyre.type}
                      season={tyre.season}
                      competitors={[]}
                      logos={[]}
                      wetRoadEfficiency={tyre.wetRoadEfficiency}
                      fuelEfficiency={tyre.fuelEfficiency}
                      brandId={tyre.brandId}
                      image={tyre.image}
                    />
                  );
                } else {
                  return (
                    <TyreToWatch
                      key={index}
                      height={tyre.height}
                      noise={tyre.noiseLevelDb}
                      noiseLevel={tyre.noiseClass}
                      loadIndex={tyre.loadIndex}
                      radius={tyre.radius}
                      speedIndex={tyre.speedIndex}
                      width={tyre.width}
                      id={tyre.id}
                      title={tyre.name}
                      model={brand?.name || ""}
                      price={getCorrectPrice(tyre, pricing)}
                      car={tyre.type}
                      kind={tyre.season}
                      competitors={[]}
                      logos={[]}
                      wetRoadEfficiency={tyre.wetRoadEfficiency}
                      fuelEfficiency={tyre.fuelEfficiency}
                      brandId={tyre.brandId}
                      image={tyre.image}
                    />
                  );
                }
              })}
            </div>
            {tyres.length === 20 || page !== 1 ? (
              <div className="flex mt-4 mb-4 justify-center">
                <button
                  className="button"
                  disabled={page === 1}
                  onClick={() => {
                    if (page === 1) {
                      return;
                    }

                    setPage(page - 1);
                  }}
                >
                  Vorige pagina
                </button>
                <div className="flex justify-center items-center ml-5">
                  {page}
                </div>
                {hasNext ? (
                  <button
                    className="button ml-5"
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    Volgende pagina
                  </button>
                ) : null}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
