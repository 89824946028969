import React, { useState } from "react";
import "./Checkout.css";
import { Link } from "react-router-dom";
import { getCheckoutUrl } from "./api/CheckoutApi";
import { Tyre } from "./api/TyreApi";
import {
  listMethodsByCountry,
  PaymentMethod as PaymentMethodType,
} from "./api/PaymentApi";
import { useEffectOnce } from "react-use";
import { isBE, isFR, isNL } from "./Utils";
import { CheckoutData } from "./App";
import { availableMethods } from "./AdminMethods";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import Visa from "./img/visa.webp";
import Mastercard from "./img/mastercard.webp";
import Meastro from "./img/meastro.png";
import Amex from "./img/amex.png";
import { Pricing } from "./api/AuthApi";

export function PaymentMethod({
  cart,
  checkoutData,
  pricing,
}: {
  cart: {
    id: string;
    brand: string;
    name: string;
    count: number;
    price: number;
    tyre: Tyre;
  }[];
  checkoutData: CheckoutData;
  pricing: Pricing;
}) {
  const { t, i18n } = useTranslation("general");

  const sendDataToGTM = useGTMDispatch();

  const [method, setMethod] = useState("bancontact");
  const [availableMethodsForUser, setAvailableMethods] = useState<
    PaymentMethodType[]
  >([]);
  const [message, setMessage] = useState<null | string>(null);

  useEffectOnce(() => {
    if (isBE() || isFR()) {
      listMethodsByCountry("be").then(result => {
        setAvailableMethods(result);
      });
    }

    if (isNL()) {
      listMethodsByCountry("nl").then(result => {
        setAvailableMethods(result);
      });
    }
  });

  const foundMethod = availableMethodsForUser.find(m => m.name === method);

  const price =
    cart.map(item => item.count * item.price).reduce((a, b) => a + b, 0) +
    (cart.filter(c => c.count === 1).length *
      pricing.onePieceExtra *
      (100 + pricing.vat)) /
      100;
  const priceWithFixed = foundMethod ? price + foundMethod.costFixed : price;
  const priceWithPercentage = foundMethod
    ? priceWithFixed * ((100 + foundMethod.costPercentage) / 100)
    : priceWithFixed;

  const handlePaymentInfo = () => {};

  return (
    <div className="checkout-container">
      <Helmet>
        <title>
          {!isNL()
            ? t("titles.paymentMethod")
            : "Betaalmethode kiezen | Banden-Online-Kopen.nl!"}
        </title>
      </Helmet>
      <div className="progress">
        <div className="item active">{t("data")}</div>
        <div className="item active">{t("payment")}</div>
        <div className="item">{t("confirmation")}</div>
      </div>
      <form
        id="proceedToMollie"
        onSubmit={async e => {
          e.preventDefault();

          if (!foundMethod) {
            return;
          }

          const url = await getCheckoutUrl(
            {
              ...checkoutData,
              method: foundMethod.id,
            },
            i18n.language
          );

          sendDataToGTM({
            event: "add_payment_info",
            ecommerce: {
              currency: "EUR",
              value: cart
                .map(item => item.count * item.price)
                .reduce((a, b) => a + b, 0)
                .toFixed(2),
              items: cart.map(item => ({
                item_id: item.id,
                item_name: item.name,
                item_brand: item.brand || "",
                quantity: item.count,
                price: item.price.toFixed(2),
                value: (item.price * item.count).toFixed(2),
                currency: "EUR",
              })),
              method: method,
            },
          });

          window.location.href = url.url;
        }}
      >
        <div className="flex justify-between checkout-wrapper">
          <form className="contact-details">
            <h1>{t("paymentMethod")}</h1>

            {availableMethodsForUser.map(item => {
              const foundOption = availableMethods.find(
                method => method.value === item.name
              );

              if (!foundOption) {
                return null;
              }

              return (
                <div
                  className="payment-method"
                  onClick={() => {
                    setMethod(foundOption.value);
                  }}
                >
                  <input
                    type="radio"
                    name="payment"
                    value={foundOption.value}
                    checked={foundOption.value === method}
                  />
                  <div className="ml-5">
                    {foundOption.label}{" "}
                    {item.costFixed === 0 && item.costPercentage === 0
                      ? "(gratis)"
                      : ""}
                  </div>
                  {foundOption.value === "creditcard" ? (
                    <>
                      <img
                        className="ml-5"
                        src={Visa}
                        alt={foundOption.label}
                      />
                      <img
                        className="ml-5"
                        src={Mastercard}
                        alt={foundOption.label}
                      />
                      <img
                        className="ml-5"
                        src={Amex}
                        alt={foundOption.label}
                      />
                      <img
                        className="ml-5"
                        src={Meastro}
                        alt={foundOption.label}
                      />
                    </>
                  ) : (
                    <img
                      className="ml-5"
                      src={foundOption.img}
                      alt={foundOption.label}
                    />
                  )}
                </div>
              );
            })}

            {message ? <div className="red mb-4">{message}</div> : null}

            <button className="button" type="submit">
              {t("continueBtn")}
            </button>
          </form>
          <div className="total total-wrapper">
            <div className="flex items-center">
              <h1>{t("cart.summary")}</h1>
              <Link className="ml-auto" to="/cart">
                {t("adjust")}
              </Link>
            </div>
            <div className="total">
              {cart.map(item => {
                return (
                  <div className="flex item">
                    <div className="flex">
                      <div>{item.count} x</div>
                      <div className="ml-5">
                        <div>
                          {item.brand} {item.name}
                        </div>
                        <div>
                          {item.tyre.width}/{item.tyre.height} R
                          {item.tyre.radius} {item.tyre.loadIndex}{" "}
                          {item.tyre.speedIndex}
                        </div>
                        <div>{item.tyre.ean}</div>
                      </div>
                    </div>
                    <div className="ml-auto">
                      <b> €{(item.price * item.count).toFixed(2)}</b>
                    </div>
                  </div>
                );
              })}
              <hr />
              <div className="flex item">
                <div>{t("cart.shipment")}:</div>
                <div className="ml-auto">
                  <b>{t("cart.free")}</b>
                </div>
              </div>
              <div className="flex item">
                <div>{t("cart.recupel")}:</div>
                <div className="ml-auto">
                  <b>{t("cart.included")}</b>
                </div>
              </div>
              {cart.filter(c => c.count === 1).length > 0 ? (
                <div className="flex item">
                  <div>Toeslag:</div>
                  <div className="ml-auto">
                    <b>
                      €
                      {(
                        (cart.filter(c => c.count === 1).length *
                          pricing.onePieceExtra *
                          (100 + pricing.vat)) /
                        100
                      ).toFixed(2)}
                    </b>
                  </div>
                </div>
              ) : null}
              <div className="flex item">
                <div>{t("cart.transactionCosts")}:</div>
                <div className="ml-auto">
                  <b>€{(priceWithPercentage - price).toFixed(2)}</b>
                </div>
              </div>
              <hr />
              <div className="flex item">
                <div>
                  <b>{t("cart.total")}:</b>
                  <div className="btw">
                    21% {t("cart.vat")} {t("cart.included")}
                  </div>
                </div>
                <div className="ml-auto">
                  <b>€{priceWithPercentage.toFixed(2)}</b>
                </div>
              </div>
              {message ? <div className="red">{message}</div> : null}
              <button className="button mt-4" type="submit">
                {t("continueBtn")}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
