import { API_ENDPOINT, getHeaders } from "./AuthApi";
import { isNL } from "../Utils";

export type CheckoutData = {
  tyres: { tyreId: string; amount: number }[];
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  partnerId: string | undefined;
  deliveryStreet: string;
  deliveryNumber: string;
  deliveryZipcode: string;
  deliveryCity: string;
  deliveryCountry: string;
  billingStreet: string;
  billingNumber: string;
  billingZipcode: string;
  billingCity: string;
  billingCountry: string;
  newsLetter: boolean;
  method: string;
};

export async function getCheckoutSave(
  data: {
    tyres: { tyreId: string; amount: number }[];
    email: string;
    firstName: string;
    lastName: string;
  },
  lang: string
): Promise<{ url: string }> {
  const result = await fetch(
    `${API_ENDPOINT}/checkout/save?lang=${lang}&country=${
      isNL() ? "nl" : lang === "fr" ? "fr" : "be"
    }`,
    {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(data),
    }
  ).then(r => r.json());

  return result;
}

export async function getCheckoutUrl(
  data: CheckoutData,
  lang: string
): Promise<{ url: string }> {
  const result = await fetch(
    `${API_ENDPOINT}/checkout?lang=${lang}&country=${
      isNL() ? "nl" : lang === "fr" ? "fr" : "be"
    }`,
    {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(data),
    }
  ).then(r => r.json());

  return result;
}
