import React, { useState } from "react";
import "./Brands.css";
import { useTranslation } from "react-i18next";
import { Brand, getBrands } from "../api/BrandApi";
import { useEffectOnce } from "react-use";
import { capitalize } from "../Utils";
import { Link } from "react-router-dom";

export function Brands({ brands }: { brands: Brand[] }) {
  const { t } = useTranslation("home");

  const [brand, setBrand] = useState<Brand[]>([]);

  useEffectOnce(() => {
    getBrands().then(result => {
      setBrand(result.filter(filter => filter.categoryId !== null));
    });
  });

  const columns = brand.slice(0, 10).map((brand, index) => (
    <div key={index} className="brandItem">
      <Link to={"/brands/" + brand.id}>
        <img src={brand.logo} alt={capitalize(brand.name)} />
      </Link>
    </div>
  ));
  return (
    <div className="containerLightGreen">
      <h2 className="mt-auto">{t("our_brands")}</h2>
      <p className="brandDescription">{t("brand_description")}</p>
      <div className="flex wrap">{columns}</div>
      <button className="brandButton">
        <Link to="/brands">{t("more_info")}</Link>
      </button>
    </div>
  );
}
