import React, { useEffect, useState } from "react";
import { AdminHeader } from "./AdminHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { getOrders, Order, syncOrder } from "./api/OrderApi";
import { faSync } from "@fortawesome/free-solid-svg-icons/faSync";
import { getTyreById, Tyre } from "./api/TyreApi";
import { useEffectOnce } from "react-use";
import { Dialog } from "./Dialog";
import { Partner } from "./api/AuthApi";
import { Brand } from "./api/BrandApi";
import { getPartnerById } from "./api/PartnerApi";
import { Select } from "./Select";
import Datetime from "react-datetime";
import moment from "moment";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export function AdminOrders({ brands }: { brands: Brand[] }) {
  const [ordersPage, setOrdersPage] = useState(1);
  const [orders, setOrders] = useState<Order[]>([]);
  const [tyres, setTyres] = useState<Tyre[]>([]);
  const [openOrder, setOpenOrder] = useState<Order | null>(null);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [search, setSearch] = useState({
    date: "",
    orderId: "",
    name: "",
    status: "paid",
  });

  const options = [
    { label: "Geannuleerd", value: "canceled" },
    { label: "Aangemaakt", value: "created" },
    { label: "Vervallen", value: "expired" },
    { label: "Betaald", value: "paid" },
    { label: "In afwachting", value: "pending" },
  ];

  async function getUniqueTyre(tyreId: string, list: Tyre[]) {
    if (list.find(t => t.id === tyreId)) {
      return;
    }

    const tyre = await getTyreById(tyreId);

    if (!tyre) {
      return;
    }

    return tyre;
  }

  async function getUniquePartner(partnerId: string, list: Partner[]) {
    if (list.find(t => t.id === partnerId)) {
      return;
    }

    const partner = await getPartnerById(partnerId);

    if (!partner) {
      return;
    }

    return partner;
  }

  useEffectOnce(() => {
    getOrders(ordersPage, search).then(async result => {
      setOrders(result);

      let tyresToConcat: Tyre[] = [];
      let partnersToConcat: Partner[] = [];

      for (const order of result) {
        for (const item of order.tyres) {
          const unique = await getUniqueTyre(item.tyreId, tyresToConcat);

          if (unique) {
            tyresToConcat = tyresToConcat.concat(unique);
          }
        }

        if (order.partnerId) {
          const unique = await getUniquePartner(
            order.partnerId,
            partnersToConcat
          );

          if (unique) {
            partnersToConcat.push(unique);
          }
        }
      }

      setTyres(tyresToConcat);
      setPartners(partnersToConcat);
    });
  });

  useEffect(() => {
    getOrders(ordersPage, search).then(async result => {
      setOrders(result);

      let tyresToConcat: Tyre[] = tyres;
      let partnersToConcat: Partner[] = partners;

      for (const order of result) {
        for (const item of order.tyres) {
          const unique = await getUniqueTyre(item.tyreId, tyresToConcat);

          if (unique) {
            tyresToConcat = tyresToConcat.concat(unique);
          }
        }

        if (order.partnerId) {
          const unique = await getUniquePartner(
            order.partnerId,
            partnersToConcat
          );

          if (unique) {
            partnersToConcat.push(unique);
          }
        }
      }

      setTyres(tyresToConcat);
      setPartners(partnersToConcat);
    });
  }, [ordersPage]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bestellingen | Admin</title>
      </Helmet>
      <AdminHeader />
      <form
        onSubmit={e => {
          e.preventDefault();
          getOrders(ordersPage, search).then(async result => {
            setOrders(result);

            let tyresToConcat: Tyre[] = tyres;
            let partnersToConcat: Partner[] = partners;

            for (const order of result) {
              for (const item of order.tyres) {
                const unique = await getUniqueTyre(item.tyreId, tyresToConcat);

                if (unique) {
                  tyresToConcat = tyresToConcat.concat(unique);
                }
              }

              if (order.partnerId) {
                const unique = await getUniquePartner(
                  order.partnerId,
                  partnersToConcat
                );

                if (unique) {
                  partnersToConcat.push(unique);
                }
              }
            }

            setTyres(tyresToConcat);
            setPartners(partnersToConcat);
          });
        }}
      >
        <div className="admin-dashboard">
          <div>
            <h1>Bestellingen</h1>
            <div className="flex">
              <button
                type={"submit"}
                className="button w-fit mt-4 mr-4"
                onClick={() => {
                  getOrders(ordersPage, search).then(result => {
                    setOrders(result);
                  });
                }}
              >
                Zoeken
              </button>
              <div
                className="button w-fit mt-4"
                onClick={() => {
                  setSearch({
                    name: "",
                    date: "",
                    orderId: "",
                    status: "paid",
                  });
                  getOrders(ordersPage, {
                    name: "",
                    date: "",
                    orderId: "",
                    status: "paid",
                  }).then(result => {
                    setOrders(result);
                  });
                }}
              >
                Reset
              </div>
            </div>
            <table className="partners-table">
              <thead>
                <th>
                  <div>Datum</div>
                  <Datetime
                    value={search.date}
                    onChange={item => {
                      setSearch({
                        ...search,
                        date: DateTime.fromJSDate(
                          moment(item).toDate()
                        ).toISO(),
                      });
                    }}
                    timeFormat={false}
                  />
                </th>
                <th>
                  <div>Order ID</div>
                  <input
                    type="text"
                    value={search.orderId}
                    onChange={e => {
                      setSearch({
                        ...search,
                        orderId: e.target.value,
                      });
                    }}
                  />
                </th>
                <th>Order number CRM</th>
                <th>
                  <div>Naam</div>
                  <input
                    type="text"
                    value={search.name}
                    onChange={e => {
                      setSearch({
                        ...search,
                        name: e.target.value,
                      });
                    }}
                  />
                </th>
                <th>Adres</th>
                <th>Email</th>
                <th>Partner</th>
                <th>Items</th>
                <th>Prijs</th>
                <th>
                  <Select
                    title={"Status"}
                    tooltip={false}
                    options={options}
                    selected={search.status}
                    selectOption={option => {
                      setSearch({
                        ...search,
                        status: option,
                      });
                    }}
                    search={false}
                  />
                </th>
                <th>Synced</th>
              </thead>
              <tbody>
                {orders.map(order => {
                  let total = 0;

                  const orderTyres = order.tyres.map(t => {
                    let extra = 0;

                    if (t.amount === 1) {
                      extra = order.onePieceExtra;
                    }

                    const priceWithRecupelAndTransport =
                      t.price + t.recupel + t.transport + extra;
                    const priceWithVat =
                      priceWithRecupelAndTransport * ((100 + order.vat) / 100);

                    total += t.amount * parseFloat(priceWithVat.toFixed(2));

                    return {
                      tyre: tyres.find(tyre => t.tyreId === tyre.id),
                      orderAmount: t.amount,
                    };
                  });
                  const partner = partners.find(p => p.id === order.partnerId);

                  return (
                    <tr className="pointer">
                      <td
                        onClick={() => {
                          setOpenOrder(order);
                        }}
                      >
                        {order.createdAt}
                      </td>
                      <td
                        onClick={() => {
                          setOpenOrder(order);
                        }}
                      >
                        {order.orderId}
                      </td>
                      <td
                        onClick={() => {
                          setOpenOrder(order);
                        }}
                      >
                        {order.externalOrderId}
                      </td>
                      <td
                        onClick={() => {
                          setOpenOrder(order);
                        }}
                      >
                        {order.firstName} {order.lastName}
                      </td>
                      <td
                        onClick={() => {
                          setOpenOrder(order);
                        }}
                      >
                        {order.deliveryStreet} {order.deliveryNumber},{" "}
                        {order.deliveryZipcode} {order.deliveryCity}{" "}
                        {order.deliveryCountry}
                      </td>
                      <td
                        onClick={() => {
                          setOpenOrder(order);
                        }}
                      >
                        {order.email}
                      </td>
                      <td
                        onClick={() => {
                          setOpenOrder(order);
                        }}
                      >
                        {partner ? partner.businessName : null}
                      </td>
                      <td
                        onClick={() => {
                          setOpenOrder(order);
                        }}
                      >
                        <div className="flex flex-column">
                          {orderTyres.map(item => {
                            const existingBrand = brands.find(
                              b => b.id === item.tyre?.brandId
                            );
                            return (
                              <div>
                                {item.orderAmount}x{" "}
                                {existingBrand ? existingBrand.name : " "}{" "}
                                {item.tyre?.name} {item.tyre?.width}/
                                {item.tyre?.height} R{item.tyre?.radius}{" "}
                                {item.tyre?.loadIndex} {item.tyre?.speedIndex}
                              </div>
                            );
                          })}
                        </div>
                      </td>
                      <td
                        onClick={() => {
                          setOpenOrder(order);
                        }}
                      >
                        <div className="flex flex-column">
                          € {(total + order.transactionCost).toFixed(2)}
                        </div>
                      </td>
                      <td
                        onClick={() => {
                          setOpenOrder(order);
                        }}
                      >
                        {order.orderStatus}
                      </td>
                      <td>
                        {order.synced ? (
                          <div className="approve">
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                        ) : null}
                        {!order.synced && order.orderStatus === "Betaald" ? (
                          <div
                            className="sync"
                            onClick={async () => {
                              const result = await syncOrder(order.orderId);

                              if (result) {
                                setOrders(
                                  orders.map(o => {
                                    if (o.orderId === order.orderId) {
                                      return {
                                        ...o,
                                        synced: true,
                                      };
                                    }

                                    return o;
                                  })
                                );
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faSync} />
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="flex mt-4 mb-4 justify-center">
              <button
                className="button"
                disabled={ordersPage === 1}
                onClick={() => {
                  if (ordersPage === 1) {
                    return;
                  }

                  setOrdersPage(ordersPage - 1);
                }}
              >
                Vorige pagina
              </button>
              <div className="flex justify-center items-center ml-5">
                {ordersPage}
              </div>
              <button
                className="button ml-5"
                onClick={() => {
                  setOrdersPage(ordersPage + 1);
                }}
              >
                Volgende pagina
              </button>
            </div>
          </div>

          <Dialog
            isOpen={openOrder !== null}
            onRequestClose={() => {
              setOpenOrder(null);
            }}
            title={"Order informatie"}
            size={"large"}
            padding={true}
          >
            {openOrder ? (
              <OrderInformation
                order={openOrder}
                partners={partners}
                tyres={tyres}
                brands={brands}
              />
            ) : null}
          </Dialog>
        </div>
      </form>
    </>
  );
}

function OrderInformation({
  order,
  partners,
  tyres,
  brands,
}: {
  order: Order;
  partners: Partner[];
  tyres: Tyre[];
  brands: Brand[];
}) {
  const partner = partners.find(p => p.id === order.partnerId);
  const orderTyres = order.tyres.map(t => {
    return {
      tyre: tyres.find(tyre => t.tyreId === tyre.id),
      orderAmount: t.amount,
      ...t,
    };
  });
  const transport: Record<number, number> = {};
  const recupel: Record<number, number> = {};

  let total = 0;

  order.tyres.forEach(tyre => {
    const currTransport = transport[tyre.transport] || 0;
    const currRecupel = recupel[tyre.recupel] || 0;

    transport[tyre.transport] = currTransport + tyre.amount;
    recupel[tyre.recupel] = currRecupel + tyre.amount;

    let extra = 0;

    if (tyre.amount === 1) {
      extra = order.onePieceExtra;
    }

    const priceWithRecupelAndTransport =
      tyre.price + tyre.recupel + tyre.transport + extra;
    const priceWithVat =
      priceWithRecupelAndTransport * ((100 + order.vat) / 100);

    total += tyre.amount * parseFloat(priceWithVat.toFixed(2));
  });

  return (
    <div>
      <div className="mb-4">
        <b>Datum</b>
        <div>{order.createdAt}</div>
      </div>
      <div className="mb-4">
        <b>Order ID (intern)</b>
        <div>{order.orderId}</div>
      </div>
      <div className="mb-4">
        <b>Order CRM (extern)</b>
        <div>{order.externalOrderId}</div>
      </div>
      <div className="mb-4">
        <b>Naam</b>
        <div>
          {order.firstName} {order.lastName}
        </div>
      </div>
      <div className="mb-4">
        <b>Adres</b>
        <div>
          {order.deliveryStreet} {order.deliveryNumber}, {order.deliveryZipcode}{" "}
          {order.deliveryCity} {order.deliveryCountry}
        </div>
      </div>
      <div className="mb-4">
        <b>Email</b>
        <div>{order.email}</div>
      </div>
      <div className="mb-4">
        <b>Telefoon</b>
        <div>{order.phoneNumber}</div>
      </div>
      <div className="mb-4">
        <b>Partner</b>
        <div>{partner ? partner.businessName : null}</div>
      </div>
      <div className="mb-4">
        <b>Items</b>
        <div>
          {orderTyres.map(item => {
            const existingBrand = brands.find(b => b.id === item.tyre?.brandId);
            return (
              <div>
                EAN:{" "}
                <Link to={"/product/" + item.tyreId} className="link">
                  {item.tyre?.ean}
                </Link>
                <br />
                {item.orderAmount}x {existingBrand ? existingBrand.name : " "}{" "}
                {item.tyre?.name} {item.tyre?.width}/{item.tyre?.height} R
                {item.tyre?.radius} {item.tyre?.loadIndex}{" "}
                {item.tyre?.speedIndex}
              </div>
            );
          })}
        </div>
      </div>
      <div className="mb-4">
        <table className="partners-table">
          <thead>
            <th>Merk</th>
            <th>W/S/R</th>
            <th>LI/SI</th>
            <th>Profiel</th>
            <th>EU Label</th>
            <th>Prijs</th>
            <th>Aantal</th>
            <th>Subtotaal</th>
          </thead>
          <tbody>
            {orderTyres.map(item => {
              const existingBrand = brands.find(
                b => b.id === item.tyre?.brandId
              );
              return (
                <tr>
                  <td>{existingBrand ? existingBrand.name : " "}</td>
                  <td>
                    {item.tyre?.width}/{item.tyre?.height}/{item.tyre?.radius}
                  </td>
                  <td>
                    {item.tyre?.loadIndex}/{item.tyre?.speedIndex}
                  </td>
                  <td>{item.tyre?.name}</td>
                  <td>{item.tyre?.environmentCategory}</td>
                  <td>€ {item.price.toFixed(2)}</td>
                  <td>{item.amount}</td>
                  <td>€ {(item.price * item.amount).toFixed(2)}</td>
                </tr>
              );
            })}
            {Object.entries(transport).map(([price, amount]) => {
              return (
                <tr>
                  <td>Transport kosten</td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>€ {price}</td>
                  <td>{amount}</td>
                  <td>€ {(parseFloat(price) * amount).toFixed(2)}</td>
                </tr>
              );
            })}
            {Object.entries(recupel).map(([price, amount]) => {
              return (
                <tr>
                  <td>Recupel</td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>€ {price}</td>
                  <td>{amount}</td>
                  <td>€ {(parseFloat(price) * amount).toFixed(2)}</td>
                </tr>
              );
            })}
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td>Toeslag</td>
              <td>
                €{" "}
                {(
                  order.tyres.filter(t => t.amount === 1).length *
                  order.onePieceExtra
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td>Subtotaal</td>
              <td>€ {total.toFixed(2)}</td>
            </tr>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td>BTW</td>
              <td>€ {(total * (order.vat / 100)).toFixed(2)}</td>
            </tr>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td>Transactiekost</td>
              <td>€ {order.transactionCost.toFixed(2)}</td>
            </tr>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td>Totaal</td>
              <td>€ {(total + order.transactionCost).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
