import React, { useEffect, useState } from "react";
import { Partner, PartnerChange, registerPartner } from "./api/AuthApi";
import { useEffectOnce } from "react-use";
import {
  approvePartner,
  approvePartnerChange,
  getApprovedPartners,
  getInactivePartners,
  getPartnerChanges,
  getUnapprovedPartners,
  inactivePartner,
  removePartnerChange,
  updatePartnerById,
} from "./api/PartnerApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { Dialog } from "./Dialog";
import { AdminHeader } from "./AdminHeader";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { Helmet } from "react-helmet";
import { Select } from "./Select";
import { Checkbox } from "./Checkbox";
import { getHour, getMinute } from "./Utils";
import { useTranslation } from "react-i18next";

export function AdminPartners() {
  const [unapprovedPartners, setUnapprovedPartners] = useState<Partner[]>([]);
  const [inactivePartners, setInactivePartners] = useState<Partner[]>([]);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [partnerChanges, setPartnerChanges] = useState<PartnerChange[]>([]);
  const [unapprovedPage, setUnapprovedPage] = useState(1);
  const [partnerPage, setPartnerPage] = useState(1);
  const [inactivePage, setInactivePage] = useState(1);
  const [openPartner, setOpenPartner] = useState<Partner | null>(null);

  useEffectOnce(() => {
    getUnapprovedPartners(unapprovedPage).then(result => {
      setUnapprovedPartners(result);
    });

    getApprovedPartners(partnerPage).then(result => {
      setPartners(result);
    });

    getPartnerChanges().then(result => {
      setPartnerChanges(result);
    });

    getInactivePartners(inactivePage).then(result => {
      setInactivePartners(result);
    });
  });

  useEffect(() => {
    getUnapprovedPartners(unapprovedPage).then(result => {
      setUnapprovedPartners(result);
    });
  }, [unapprovedPage]);

  useEffect(() => {
    getApprovedPartners(partnerPage).then(result => {
      setPartners(result);
    });
  }, [partnerPage]);

  useEffect(() => {
    getInactivePartners(inactivePage).then(result => {
      setInactivePartners(result);
    });
  }, [inactivePage]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Partners | Admin</title>
      </Helmet>
      <AdminHeader />
      <div className="admin-dashboard">
        <>
          <div>
            <h1>Partners</h1>
            <h3>Niet geverifieerde partners</h3>
            <table className="partners-table">
              <thead>
                <th>Naam</th>
                <th>Adres</th>
                <th>Email</th>
                <th />
              </thead>
              <tbody>
                {unapprovedPartners.map(partner => {
                  return (
                    <tr>
                      <td
                        onClick={() => {
                          setOpenPartner(partner);
                        }}
                      >
                        {partner.businessName}
                      </td>
                      <td
                        onClick={() => {
                          setOpenPartner(partner);
                        }}
                      >
                        {partner.street} {partner.number}, {partner.zipcode}{" "}
                        {partner.city}
                      </td>
                      <td
                        onClick={() => {
                          setOpenPartner(partner);
                        }}
                      >
                        {partner.email}
                      </td>
                      <td>
                        <div className="flex">
                          <div
                            className="approve"
                            onClick={async () => {
                              await approvePartner(partner.id);

                              setUnapprovedPartners(
                                unapprovedPartners.filter(
                                  p => p.id !== partner.id
                                )
                              );

                              setPartners(partners.concat(partner));
                            }}
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                          <div
                            className="sync ml-5"
                            onClick={async () => {
                              await inactivePartner(partner.id);

                              setUnapprovedPartners(
                                unapprovedPartners.filter(
                                  p => p.id !== partner.id
                                )
                              );

                              setInactivePartners(
                                inactivePartners.concat(partner)
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="flex mt-4 mb-4 justify-center">
              <button
                className="button"
                disabled={unapprovedPage === 1}
                onClick={() => {
                  if (unapprovedPage === 1) {
                    return;
                  }

                  setUnapprovedPage(unapprovedPage - 1);
                }}
              >
                Vorige pagina
              </button>
              <button
                className="button ml-5"
                onClick={() => {
                  setUnapprovedPage(unapprovedPage + 1);
                }}
              >
                Volgende pagina
              </button>
            </div>
          </div>
          <div>
            <h3>Geverifieerde partners</h3>
            <table className="partners-table">
              <thead>
                <th>Naam</th>
                <th>Adres</th>
                <th>Email</th>
                <th />
              </thead>
              <tbody>
                {partners.map(partner => {
                  return (
                    <tr>
                      <td
                        onClick={() => {
                          setOpenPartner(partner);
                        }}
                      >
                        {partner.businessName}
                      </td>
                      <td
                        onClick={() => {
                          setOpenPartner(partner);
                        }}
                      >
                        {partner.street} {partner.number}, {partner.zipcode}{" "}
                        {partner.city}
                      </td>
                      <td
                        onClick={() => {
                          setOpenPartner(partner);
                        }}
                      >
                        {partner.email}
                      </td>
                      <td>
                        <div
                          className="sync"
                          onClick={async () => {
                            await inactivePartner(partner.id);

                            setPartners(
                              partners.filter(p => p.id !== partner.id)
                            );

                            setInactivePartners(
                              inactivePartners.concat(partner)
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="flex mt-4 mb-4 justify-center">
              <button
                className="button"
                disabled={partnerPage === 1}
                onClick={() => {
                  if (partnerPage === 1) {
                    return;
                  }

                  setPartnerPage(partnerPage - 1);
                }}
              >
                Vorige pagina
              </button>
              <button
                className="button ml-5"
                onClick={() => {
                  setPartnerPage(partnerPage + 1);
                }}
              >
                Volgende pagina
              </button>
            </div>
          </div>
          <div>
            <h3>Niet-actieve partners</h3>
            <table className="partners-table">
              <thead>
                <th>Naam</th>
                <th>Adres</th>
                <th>Email</th>
                <th />
              </thead>
              <tbody>
                {inactivePartners.map(partner => {
                  return (
                    <tr>
                      <td
                        onClick={() => {
                          setOpenPartner(partner);
                        }}
                      >
                        {partner.businessName}
                      </td>
                      <td
                        onClick={() => {
                          setOpenPartner(partner);
                        }}
                      >
                        {partner.street} {partner.number}, {partner.zipcode}{" "}
                        {partner.city}
                      </td>
                      <td
                        onClick={() => {
                          setOpenPartner(partner);
                        }}
                      >
                        {partner.email}
                      </td>
                      <td>
                        <div
                          className="approve"
                          onClick={async () => {
                            await approvePartner(partner.id);

                            setInactivePartners(
                              inactivePartners.filter(p => p.id !== partner.id)
                            );

                            setPartners(partners.concat(partner));
                          }}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="flex mt-4 mb-4 justify-center">
              <button
                className="button"
                disabled={partnerPage === 1}
                onClick={() => {
                  if (partnerPage === 1) {
                    return;
                  }

                  setPartnerPage(partnerPage - 1);
                }}
              >
                Vorige pagina
              </button>
              <button
                className="button ml-5"
                onClick={() => {
                  setPartnerPage(partnerPage + 1);
                }}
              >
                Volgende pagina
              </button>
            </div>
          </div>
          <h3>Partner aanpassingen</h3>
          <table className="partners-table">
            <thead>
              <th>Naam</th>
              <th>Adres</th>
              <th>IBAN</th>
              <th>Vat</th>
              <th />
            </thead>
            <tbody>
              {partnerChanges.map(partner => {
                return (
                  <tr>
                    <td>{partner.name}</td>
                    <td>
                      {partner.street} {partner.number}, {partner.zipcode}{" "}
                      {partner.city}
                    </td>
                    <td>{partner.iban}</td>
                    <td>{partner.vat}</td>
                    <td>
                      <div className="flex">
                        <div
                          className="approve"
                          onClick={async () => {
                            await approvePartnerChange(partner.id);

                            setPartnerChanges(
                              partnerChanges.filter(p => p.id !== partner.id)
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </div>
                        <div
                          className="sync ml-5"
                          onClick={async () => {
                            await removePartnerChange(partner.id);

                            setPartnerChanges(
                              partnerChanges.filter(p => p.id !== partner.id)
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
        <Dialog
          isOpen={openPartner !== null}
          onRequestClose={() => {
            setOpenPartner(null);
          }}
          title={"PartnerPage informatie"}
          size={"large"}
          padding={true}
        >
          {openPartner ? (
            <PartnerInformation
              partner={openPartner}
              onClose={p => {
                setPartners(
                  partners.map(pa => {
                    if (pa.id === p.id) {
                      return p;
                    }

                    return pa;
                  })
                );
                setInactivePartners(
                  inactivePartners.map(pa => {
                    if (pa.id === p.id) {
                      return p;
                    }

                    return pa;
                  })
                );
                setUnapprovedPartners(
                  unapprovedPartners.map(pa => {
                    if (pa.id === p.id) {
                      return p;
                    }

                    return pa;
                  })
                );
                setOpenPartner(null);
              }}
            />
          ) : null}
        </Dialog>
      </div>
    </>
  );
}

function PartnerInformation({
  partner,
  onClose,
}: {
  partner: Partner;
  onClose: (partner: Partner) => void;
}) {
  const [data, setData] = useState<Partner>(partner);
  const [weekdaysSame, setWeekdaysSame] = useState(false);
  const { t } = useTranslation("partnerLogin");

  return (
    <form
      id="partnerRegister"
      onSubmit={async e => {
        e.preventDefault();

        await updatePartnerById(data);
        onClose(data);
      }}
    >
      <h2>{t("register.generalInformation")}</h2>
      <label>{t("register.companyName")}</label>
      <input
        value={data.businessName}
        onChange={e => {
          setData({
            ...data,
            businessName: e.target.value,
          });
        }}
        className="input"
        type="text"
        placeholder={t("register.companyName")}
        required={true}
      />
      <label>{t("register.contactName")}</label>
      <input
        value={data.name}
        onChange={e => {
          setData({
            ...data,
            name: e.target.value,
          });
        }}
        className="input"
        type="text"
        placeholder={t("register.contactName")}
        required={true}
      />
      <label>{t("login.email")}</label>
      <input
        value={data.email}
        onChange={e => {
          setData({
            ...data,
            email: e.target.value,
          });
        }}
        className="input"
        type="email"
        placeholder={t("login.email")}
        required={true}
      />
      <label>{t("register.street")}</label>
      <input
        value={data.street}
        onChange={e => {
          setData({
            ...data,
            street: e.target.value,
          });
        }}
        className="input"
        type="text"
        placeholder={t("register.street")}
        required={true}
      />
      <label>{t("register.number")}</label>
      <input
        value={data.number}
        onChange={e => {
          setData({
            ...data,
            number: e.target.value,
          });
        }}
        className="input"
        type="text"
        placeholder={t("register.number")}
        required={true}
      />
      <label>{t("register.zipcode")}</label>
      <input
        value={data.zipcode}
        onChange={e => {
          setData({
            ...data,
            zipcode: e.target.value,
          });
        }}
        className="input"
        type="text"
        placeholder={t("register.zipcode")}
        required={true}
      />
      <label>{t("register.place")}</label>
      <input
        value={data.city}
        onChange={e => {
          setData({
            ...data,
            city: e.target.value,
          });
        }}
        className="input"
        type="text"
        placeholder={t("register.place")}
        required={true}
      />
      <Select
        search={false}
        title={t("register.country").toString()}
        tooltip={false}
        options={[
          { label: "België", value: "BE" },
          { label: "Nederland", value: "NL" },
        ]}
        selected={data.country}
        selectOption={selected => {
          setData({
            ...data,
            country: selected,
          });
        }}
      />
      <label>{t("register.phone")}</label>
      <input
        value={data.phone}
        onChange={e => {
          setData({
            ...data,
            phone: e.target.value,
          });
        }}
        className="input"
        type="text"
        placeholder={t("register.phone")}
        required={true}
      />
      <label>IBAN</label>
      <input
        value={data.iban}
        onChange={e => {
          setData({
            ...data,
            iban: e.target.value,
          });
        }}
        className="input"
        type="text"
        placeholder="IBAN"
      />
      <label>{t("register.TAV")}</label>
      <input
        value={data.vat}
        onChange={e => {
          setData({
            ...data,
            vat: e.target.value,
          });
        }}
        className="input"
        type="text"
        placeholder={t("register.TAV")}
        required={true}
      />
      <Select
        search={false}
        title={t("register.max").toString()}
        tooltip={false}
        options={[
          { label: "10", value: "10" },
          { label: "11", value: "11" },
          { label: "12", value: "12" },
          { label: "13", value: "13" },
          { label: "14", value: "14" },
          { label: "15", value: "15" },
          { label: "16", value: "16" },
          { label: "17", value: "17" },
          { label: "18", value: "18" },
          { label: "19", value: "19" },
          { label: "20", value: "20" },
          { label: "21", value: "21" },
          { label: "22", value: "22" },
          { label: "23", value: "23" },
          { label: "24", value: "24" },
        ]}
        selected={data.maxInchToMount.toString()}
        selectOption={selected => {
          setData({
            ...data,
            maxInchToMount: parseInt(selected),
          });
        }}
      />
      <h2>{t("register.prices")}</h2>
      <label>{t("register.price10")}</label>
      <input
        value={data.placementPrizes.inch10}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch10: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price10")}
      />
      <label>{t("register.price11")}</label>
      <input
        value={data.placementPrizes.inch11}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch11: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price11")}
      />
      <label>{t("register.price12")}</label>
      <input
        value={data.placementPrizes.inch12}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch12: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price12")}
      />
      <label>{t("register.price13")}</label>
      <input
        value={data.placementPrizes.inch13}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch13: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price13")}
      />
      <label>{t("register.price14")}</label>
      <input
        value={data.placementPrizes.inch14}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch14: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price14")}
      />
      <label>{t("register.price15")}</label>
      <input
        value={data.placementPrizes.inch15}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch15: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price15")}
      />
      <label>{t("register.price16")}</label>
      <input
        value={data.placementPrizes.inch16}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch16: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price16")}
      />
      <label>{t("register.price17")}</label>
      <input
        value={data.placementPrizes.inch17}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch17: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price17")}
      />
      <label>{t("register.price18")}</label>
      <input
        value={data.placementPrizes.inch18}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch18: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price18")}
      />
      <label>{t("register.price19")}</label>
      <input
        value={data.placementPrizes.inch19}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch19: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price19")}
      />
      <label>{t("register.price20")}</label>
      <input
        value={data.placementPrizes.inch20}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch20: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price20")}
      />
      <label>{t("register.price21")}</label>
      <input
        value={data.placementPrizes.inch21}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch21: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price21")}
      />
      <label>{t("register.price22")}</label>
      <input
        value={data.placementPrizes.inch22}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch22: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price22")}
      />
      <label>{t("register.price23")}</label>
      <input
        value={data.placementPrizes.inch23}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch23: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price23")}
      />
      <label>{t("register.price24")}</label>
      <input
        value={data.placementPrizes.inch24}
        onChange={e => {
          setData({
            ...data,
            placementPrizes: {
              ...data.placementPrizes,
              inch24: parseFloat(e.target.value),
            },
          });
        }}
        className="input"
        type="number"
        min={0}
        step={0.01}
        placeholder={t("register.price24")}
      />
      <Select
        search={false}
        title={t("register.runflat").toString()}
        tooltip={false}
        options={[
          { label: t("register.yes"), value: "ja" },
          { label: t("register.no"), value: "nee" },
        ]}
        selected={data.runFlat ? "ja" : "nee"}
        selectOption={selected => {
          setData({
            ...data,
            runFlat: selected === "ja",
          });
        }}
      />
      {data.runFlat ? (
        <>
          <label>{t("register.extraPriceRft")}</label>
          <input
            value={data.runFlatAmount}
            onChange={e => {
              setData({
                ...data,
                runFlatAmount: parseFloat(e.target.value),
              });
            }}
            className="input"
            type="number"
            min={0}
            step={0.01}
            placeholder={t("register.extraPriceRft")}
            required={data.runFlat}
          />
        </>
      ) : null}
      <h2>{t("register.extraServices.title")}</h2>
      <Checkbox
        title={t("register.extraServices.extraService1")}
        value={data.tyreStorage}
        onClick={clicked => {
          setData({
            ...data,
            tyreStorage: clicked,
          });
        }}
      />
      <Checkbox
        title={t("register.extraServices.extraService2")}
        value={data.rimCleaning}
        onClick={clicked => {
          setData({
            ...data,
            rimCleaning: clicked,
          });
        }}
      />
      <Checkbox
        title={t("register.extraServices.extraService3")}
        value={data.aligning}
        onClick={clicked => {
          setData({
            ...data,
            aligning: clicked,
          });
        }}
      />
      <Checkbox
        title={t("register.extraServices.extraService4")}
        value={data.fillNitrogen}
        onClick={clicked => {
          setData({
            ...data,
            fillNitrogen: clicked,
          });
        }}
      />
      <h2>{t("register.openingHours")}</h2>
      <table>
        <tbody>
          <tr>
            <td />
            <td>
              <div className="flex">
                <div className="mr-auto">Voormiddag van - tot</div>
                <div>Namiddag van - tot</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Ma</td>
            <td>
              <div className="flex items-center">
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        startHourWeek:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      if (weekdaysSame) {
                        newData["startHourTue"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                        newData["startHourWed"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                        newData["startHourThu"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                        newData["startHourFri"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                      }

                      setData(newData);
                    }
                  }}
                  value={data?.startHourWeek}
                  onChange={e => {
                    let newData = {
                      ...data!,
                      startHourWeek: e.target.value,
                    };

                    if (weekdaysSame) {
                      newData["startHourTue"] = e.target.value;
                      newData["startHourWed"] = e.target.value;
                      newData["startHourThu"] = e.target.value;
                      newData["startHourFri"] = e.target.value;
                    }

                    setData(newData);
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        startHourWeekMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      if (weekdaysSame) {
                        newData["startHourTueMid"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                        newData["startHourWedMid"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                        newData["startHourThuMid"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                        newData["startHourFriMid"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                      }

                      setData(newData);
                    }
                  }}
                  value={data?.startHourWeekMid}
                  onChange={e => {
                    let newData = {
                      ...data!,
                      startHourWeekMid: e.target.value,
                    };

                    if (weekdaysSame) {
                      newData["startHourTueMid"] = e.target.value;
                      newData["startHourWedMid"] = e.target.value;
                      newData["startHourThuMid"] = e.target.value;
                      newData["startHourFriMid"] = e.target.value;
                    }

                    setData(newData);
                  }}
                  placeholder={`hh:mm`}
                />
                <div className="ml-4 mr-4">-</div>
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourWeekMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      if (weekdaysSame) {
                        newData["endHourTueMid"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                        newData["endHourWedMid"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                        newData["endHourThuMid"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                        newData["endHourFriMid"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                      }

                      setData(newData);
                    }
                  }}
                  value={data?.endHourWeekMid}
                  onChange={e => {
                    let newData = {
                      ...data!,
                      endHourWeekMid: e.target.value,
                    };

                    if (weekdaysSame) {
                      newData["endHourTueMid"] = e.target.value;
                      newData["endHourWedMid"] = e.target.value;
                      newData["endHourThuMid"] = e.target.value;
                      newData["endHourFriMid"] = e.target.value;
                    }

                    setData(newData);
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourWeek:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      if (weekdaysSame) {
                        newData["endHourTue"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                        newData["endHourWed"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                        newData["endHourThu"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                        newData["endHourFri"] =
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00");
                      }

                      setData(newData);
                    }
                  }}
                  value={data?.endHourWeek}
                  onChange={e => {
                    let newData = {
                      ...data!,
                      endHourWeek: e.target.value,
                    };

                    if (weekdaysSame) {
                      newData["endHourTue"] = e.target.value;
                      newData["endHourWed"] = e.target.value;
                      newData["endHourThu"] = e.target.value;
                      newData["endHourFri"] = e.target.value;
                    }

                    setData(newData);
                  }}
                  placeholder={`hh:mm`}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <Checkbox
                title={"Dit geldt voor alle weekdagen"}
                value={weekdaysSame}
                onClick={checked => {
                  setWeekdaysSame(checked);

                  if (checked) {
                    let newData = {
                      ...data!,
                    };

                    newData["startHourTue"] = data!.startHourWeek;
                    newData["startHourWed"] = data!.startHourWeek;
                    newData["startHourThu"] = data!.startHourWeek;
                    newData["startHourFri"] = data!.startHourWeek;

                    newData["startHourTueMid"] = data!.startHourWeekMid;
                    newData["startHourWedMid"] = data!.startHourWeekMid;
                    newData["startHourThuMid"] = data!.startHourWeekMid;
                    newData["startHourFriMid"] = data!.startHourWeekMid;

                    newData["endHourTueMid"] = data!.endHourWeekMid;
                    newData["endHourWedMid"] = data!.endHourWeekMid;
                    newData["endHourThuMid"] = data!.endHourWeekMid;
                    newData["endHourFriMid"] = data!.endHourWeekMid;

                    newData["endHourTue"] = data!.endHourWeek;
                    newData["endHourWed"] = data!.endHourWeek;
                    newData["endHourThu"] = data!.endHourWeek;
                    newData["endHourFri"] = data!.endHourWeek;

                    setData(newData);
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Di</td>
            <td>
              <div className="flex items-center">
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        startHourTue:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.startHourTue}
                  onChange={e => {
                    setData({
                      ...data!,
                      startHourTue: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        startHourTueMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.startHourTueMid}
                  onChange={e => {
                    setData({
                      ...data!,
                      startHourTueMid: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <div className="ml-4 mr-4">-</div>
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourTueMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.endHourTueMid}
                  onChange={e => {
                    setData({
                      ...data!,
                      endHourTueMid: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourTue:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.endHourTue}
                  onChange={e => {
                    setData({
                      ...data!,
                      endHourTue: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Wo</td>
            <td>
              <div className="flex items-center">
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        ...data!,
                        startHourWed:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.startHourWed}
                  onChange={e => {
                    setData({
                      ...data!,
                      startHourWed: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        startHourWedMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.startHourWedMid}
                  onChange={e => {
                    setData({
                      ...data!,
                      startHourWedMid: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <div className="ml-4 mr-4">-</div>
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourWedMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.endHourWedMid}
                  onChange={e => {
                    setData({
                      ...data!,
                      endHourWedMid: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourWed:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.endHourWed}
                  onChange={e => {
                    setData({
                      ...data!,
                      endHourWed: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Do</td>
            <td>
              <div className="flex items-center">
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        startHourThu:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.startHourThu}
                  onChange={e => {
                    setData({
                      ...data!,
                      startHourThu: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        startHourThuMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.startHourThuMid}
                  onChange={e => {
                    setData({
                      ...data!,
                      startHourThuMid: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <div className="ml-4 mr-4">-</div>
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourThuMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.endHourThuMid}
                  onChange={e => {
                    setData({
                      ...data!,
                      endHourThuMid: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourThu:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.endHourThu}
                  onChange={e => {
                    setData({
                      ...data!,
                      endHourThu: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Vr</td>
            <td>
              <div className="flex items-center">
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  value={data?.startHourFri}
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        startHourFri:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  onChange={e => {
                    setData({
                      ...data!,
                      startHourFri: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        startHourFriMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.startHourFriMid}
                  onChange={e => {
                    setData({
                      ...data!,
                      startHourFriMid: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <div className="ml-4 mr-4">-</div>
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourFriMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.endHourFriMid}
                  onChange={e => {
                    setData({
                      ...data!,
                      endHourFriMid: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourFri:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.endHourFri}
                  onChange={e => {
                    setData({
                      ...data!,
                      endHourFri: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Za</td>
            <td>
              <div className="flex items-center">
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        startHourSaturday:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.startHourSaturday}
                  onChange={e => {
                    setData({
                      ...data!,
                      startHourSaturday: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        startHourSaturdayMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.startHourSaturdayMid}
                  onChange={e => {
                    setData({
                      ...data!,
                      startHourSaturdayMid: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <div className="ml-4 mr-4">-</div>
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourSaturdayMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.endHourSaturdayMid}
                  onChange={e => {
                    setData({
                      ...data!,
                      endHourSaturdayMid: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourSaturday:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.endHourSaturday}
                  onChange={e => {
                    setData({
                      ...data!,
                      endHourSaturday: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Zo</td>
            <td>
              <div className="flex items-center">
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        startHourSunday:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.startHourSunday}
                  onChange={e => {
                    setData({
                      ...data!,
                      startHourSunday: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        startHourSundayMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.startHourSundayMid}
                  onChange={e => {
                    setData({
                      ...data!,
                      startHourSundayMid: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <div className="ml-4 mr-4">-</div>
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourSundayMid:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.endHourSundayMid}
                  onChange={e => {
                    setData({
                      ...data!,
                      endHourSundayMid: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
                <input
                  className="input xxsmall"
                  type="text"
                  pattern="[0-9][0-9]:[0-9][0-9]"
                  title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                  onBlur={e => {
                    e.target.value = e.target.value.padStart(2, "0");
                    if (e.target.value.match("^[0-9][0-9]:?$")) {
                      let newData = {
                        ...data!,
                        endHourSunday:
                          e.target.value +
                          (e.target.value.includes(":") ? "00" : ":00"),
                      };

                      setData(newData);
                    }
                  }}
                  value={data?.endHourSunday}
                  onChange={e => {
                    setData({
                      ...data!,
                      endHourSunday: e.target.value,
                    });
                  }}
                  placeholder={`hh:mm`}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <button className="button">Opslaan</button>
    </form>
  );
}
